/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
          /* SLIDER HOME */
          $('.slider-home').slick({
              dots: false,
              arrows: false,
              infinite: true,
              speed: 500,
              fade: true
          });

          /* SLIDER PRODUCTS */
          $('.module-slider-products').slick({
              slidesToShow: 3,
              slidesToScroll: 3,
              dots: true,
              arrows: false,
              infinite: true,
              responsive: [
                  {
                      breakpoint: 991,
                      settings: {
                          slidesToShow: 2,
                          slidesToScroll: 2
                      }
                  },
                  {
                      breakpoint: 575,
                      settings: {
                          slidesToShow: 1,
                          slidesToScroll: 1
                      }
                  }
              ]
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        /*  MENU - MOBILE */
          $('.hamburger').on('click', function() {
              $(this).fadeOut("fast");
              $('.page-container').addClass("show").animate({"margin-left": '-=300'}, function(){
                  $('.btn-close-nav-mobile').fadeIn("fast");
              });
              $('.nav-mobile').addClass("show");
              $('.nav-mobile').animate({"right": '+=300'});
          });
          $('.btn-close-nav-mobile').on('click', function() {
              $(this).fadeOut("fast");
              $('.page-container').animate({"margin-left": '+=300'}, function() {
                  $(this).removeClass("show");
                  $('.hamburger').fadeIn("fast");
              });
              $('.nav-mobile').animate({"right": '-=300'});
              $('.nav-mobile').removeClass("show");
          });

        /* MENU - SUBMENU - MOBILE */
          $(".nav-mobile li > span").on('click', function() {
              var btnMenu = $(this);
              var submenu = $(this).closest("li").find(".sub-menu-mobile");
              submenu.slideToggle("fast");
          });

          /*  TITLE HEADER - IMG HEIGHT = CONTENT */
          var height_title =  $( ".page-header-content .page-header-img-desktop" ).next(".page-header-title").outerHeight();
          $( ".page-header-content .page-header-img-desktop" ).css("height", height_title);

          /*  SLIDE - IMG HEIGHT = CONTENT */
          $( ".sh-slide .sh-slide-img-desktop" ).each(function( index ) {
              var height_content =  $(this).next(".sh-slide-content").outerHeight();
              $(this).css("height", height_content);
          });

          /*  RESIZE */
          $(window).on("resize", function () {
              /*  TITLE HEADER - IMG HEIGHT = CONTENT */
              var height_title =  $( ".page-header-content .page-header-img-desktop" ).next(".page-header-title").outerHeight();
              $( ".page-header-content .page-header-img-desktop" ).css("height", height_title);

              /*  SLIDE - IMG HEIGHT = CONTENT */
              $( ".sh-slide .sh-slide-img-desktop" ).each(function( index ) {
                  var height_content =  $(this).next(".sh-slide-content").outerHeight();
                  $(this).css("height", height_content);
              });
          });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
